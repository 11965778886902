@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #111827;
    color: white;
}

.bg {
    background-color: #111827;
}

.subbg {
    background-color: #1f2937;
}

.bluebtn {
    background-color: #3b82f6;
}